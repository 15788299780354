/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from "@datadog/browser-rum";

import _initAppcues from "./appcues/_appcues";
import _initChameleon from "./chameleon/_chameleon";
import _initPendo from "./pendo/_pendo";

declare global {
  let pendo: any;
  let Appcues: any;
  let chmln: any;
  interface Window {
    pendo: any;
    Appcues: any;
    chmln: any;
  }
}

export default class CanaryAnalytics {
  constructor() {
    try {
      if (process.env.VUE_APP_ENV === "dev") {
        // prevent from being initialized in dev
        // all calls are sent to noop function.
        const propertyProxy = new Proxy(
          {},
          {
            get: () => {
              return () => {};
            },
          },
        );
        window.pendo = propertyProxy;
        window.Appcues = propertyProxy;
        window.chmln = propertyProxy;
      } else {
        _initPendo();
        _initAppcues();
        _initChameleon();
        datadogRum.init({
          applicationId: "d926b666-3caa-4e39-b018-24034b3c6b49",
          clientToken: "pubc6ef83c0f281f0eb2d6f9cc9e4592bb8",
          site: "datadoghq.com",
          service: "canary",
          env: process.env.VUE_APP_ENV,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: "mask-user-input",
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  static initialize(
    userId: string | undefined,
    userProperties: Record<string, string | number | boolean | null>,
    {
      id: accountId,
      ...accountProperties
    }: Record<string, string | number> = {},
  ): void {
    // if specified, both the user and account should have a unique id
    try {
      pendo.initialize({
        visitor: { id: userId, ...userProperties },
        account: { id: accountId, ...accountProperties },
      });
      Appcues.identify(userId, userProperties);
      if (accountId) {
        Appcues.group(accountId, accountProperties);
      }
      chmln.identify(userId, {
        ...userProperties,
        company: { uid: accountId, ...accountProperties },
      });
      datadogRum.setUser({
        id: userId,
        ...userProperties,
      });
    } catch (err) {
      console.error(err);
    }
  }

  // Set user identities
  static identify(
    userId: string,
    userProperties: Record<string, string | number>,
    {
      id: accountId,
      ...accountProperties
    }: Record<string, string | number> = {},
  ): void {
    // if specified, both the user and account should have a unique id
    try {
      // eslint-disable-next-line no-undef
      pendo.identify(
        { id: userId, ...userProperties },
        { id: accountId, ...accountProperties },
      );
      Appcues.identify(userId, userProperties);
      if (accountId) {
        Appcues.group(accountId, accountProperties);
      }
      chmln.identify(userId, {
        ...userProperties,
        company: { uid: accountId, ...accountProperties },
      });
      for (const [key, value] of Object.entries(userProperties)) {
        datadogRum.setUserProperty(key, value);
      }
    } catch (err) {
      console.error(err);
    }
  }

  static track = (
    trackType: string,
    metadata?: Record<string, unknown>,
  ): void => {
    try {
      pendo.track(trackType, metadata);
      Appcues.track(trackType, metadata);
      // chameleon does not support event metadata.
      chmln.track(trackType);
      datadogRum.addAction(trackType, metadata);
    } catch (err) {
      console.error(err);
    }
  };
}
