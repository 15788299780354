/* eslint-disable */
// this script is from chameleon initialization and should not be called directly
export default function _initChameleon() {
  /* chameleon.io script */

  !(function (d, w) {
    var t =
        "S8aiOZ8dQgCyK9uvAWoB9Ubq1DNBQCOTnq7243ycfxZJG3-1SqPVg-Fh2XQa3daAJotukc",
      c = "chmln",
      i = d.createElement("script");
    if ((w[c] || (w[c] = {}), !w[c].root)) {
      (w[c].accountToken = t),
        (w[c].location = w.location.href.toString()),
        (w[c].now = new Date()),
        (w[c].fastUrl = "https://fast.chameleon.io/");
      var m =
        "identify alias track clear set show on off custom help _data".split(
          " ",
        );
      for (var s = 0; s < m.length; s++) {
        !(function () {
          var t = (w[c][m[s] + "_a"] = []);
          w[c][m[s]] = function () {
            t.push(arguments);
          };
        })();
      }
      (i.src = w[c].fastUrl + "messo/" + t + "/messo.min.js"),
        (i.async = !0),
        d.head.appendChild(i);
    }
  })(document, window);
}
